import React from "react"
// import styled from "styled-components"

import { FlexCenter, LinearTitle } from "../styles/elements.js"

import "normalize.css" // Note this
import Seo from "../components/Seo"
import Layout from "../components/layout.js"

import BlogList from "../Blog/BlogList"
import Featured from "../components/Featured"

// const BlockWrap = styled.div`
//   width: 100%;
// `

// const Block = styled.div`
//   margin: 0 auto;
//   min-width: 70%;
//   max-width: 70%;
//   display: flex;
//   /* background: black;
//   border: 1px dashed white; */
//   padding: 10px 0px 10px 10px;
//   a {
//     display: flex;
//     text-decoration: none;
//     padding-left: 1%;
//     color: white;
//     // font-weight: bold;
//   }
//   a div {
//     padding-left: 5px;
//   }
//   @media (min-width: 200px) {
//     min-width: 90%;
//   }
// `

const Blog = () => {
  return (
    <>
      <Layout>
        <Seo
          title="Improve your skills with our Blog"
          keywords="blog, compound yourself, self improvement, skills, discover, online learning, online, learning"
        />

        <Featured height="50vh">
          <FlexCenter>
            <LinearTitle>Blog</LinearTitle>
          </FlexCenter>
          <>
            <BlogList />
          </>
        </Featured>
      </Layout>
    </>
  )
}

export default Blog
